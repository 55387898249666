import React, { FC, ReactNode } from "react";
import { useCaptureSession } from "hooks/use-session";

import styles from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

const AppLayout: FC<Props> = ({ children }) => {
  useCaptureSession();

  return <div className={styles.app}>{children}</div>;
};

export default AppLayout;
