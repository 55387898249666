import Modal from "components/Modal";
import React, { FC } from "react";
import { IoMdClose as CloseIcon } from "react-icons/io";

import Label from "components/Label";
import Form from "components/Form";
import Button from "components/Button";
import Media from "components/Media";
import Logo from "../../../public/full-logo-primary.svg";
import SignUpBackgroundResponsive from "../../../public/sign-up-background-responsive.png";
import SignUpBackground from "../../../public/sign-up-background.png";
import styles from "./styles.module.scss";

type Props = {
  handleClose(): void;
  visible: boolean;
};

const SignUpModal: FC<Props> = ({ handleClose, visible }) => {
  return (
    <Modal contentClassName={styles.modal} withHeader={false} visible={visible}>
      <div className={styles.container}>
        <Media className={styles.closeButtonWrapper} at={["mobile"]}>
          <Button
            variant="text"
            className={styles.closeButton}
            onClick={handleClose}
            Icon={<CloseIcon className={styles.closeIcon} />}
          />
        </Media>
        <Media at={["desktop"]} className={styles.left}>
          <img alt="" src={SignUpBackground.src} className={styles.image} />
        </Media>
        <Media at={["mobile"]} className={styles.left}>
          <img
            alt=""
            src={SignUpBackgroundResponsive.src}
            className={styles.image}
          />
        </Media>
        <div className={styles.right}>
          <Media className={styles.closeButtonWrapper} at={["desktop"]}>
            <Button
              variant="text"
              className={styles.closeButton}
              onClick={handleClose}
              Icon={<CloseIcon className={styles.closeIcon} />}
            />
          </Media>
          <Logo className={styles.logo} />
          <Label variant="h4-centered" color="primary">
            Unlock your free estimate
          </Label>
          <Label
            className={styles.subtitle}
            variant="s2-centered"
            color="primary"
          >
            ...to start living in your home like a renter.
          </Label>
          <Form
            className={styles.form}
            fields={[
              {
                type: "text",
                id: "email",
                placeholder: "Your email",
                props: { className: styles.field }
              }
            ]}
            onSubmit={() => {}}
          >
            {() => (
              <Button type="submit" className={styles.submit} variant="accent">
                Continue
              </Button>
            )}
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default SignUpModal;
