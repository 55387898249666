import React, { useState, useEffect } from "react";
import { RecoilRoot } from "recoil";
import { ThemeCreator } from "theme/ThemeCreator";
import { ExtendedAppProps, GetLayout } from "types/pages";
import { Toaster } from "react-hot-toast";
import Script from "next/script";
import * as snippet from "@segment/snippet";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Router, { useRouter } from "next/router";

import "../styles/globals.css";
import "fonts/basis-grotesque-pro/index.css";
import "fonts/sanomat/index.css";

import SignUpModal from "components/SignUpModal";

import AppLayout from "./app_layout";

function renderSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
    page: true
  };

  if (process.env.NODE_ENV === "development") {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

NProgress.configure({
  minimum: 0.3,
  easing: "ease",
  speed: 800,
  showSpinner: false
});

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", (url) => {
  NProgress.done();
  window.analytics.page(url);
});
Router.events.on("routeChangeError", () => NProgress.done());

function MyApp({ Component, pageProps }: ExtendedAppProps) {
  const [signUpModalVisible, setSignUpModalVisible] = useState(false);
  const [loadGrowSurf, setLoadGrowSurf] = useState(false);
  const { route, query } = useRouter();

  const getLayout: GetLayout = Component.getLayout || ((page) => page);

  useEffect(() => {
    if (query.grsf || ["/share", "/start/form"].includes(route)) {
      setLoadGrowSurf(true);
    }
  }, [query.grsf, route]);

  return (
    <RecoilRoot>
      <ThemeCreator>
        <SignUpModal
          visible={signUpModalVisible}
          handleClose={() => setSignUpModalVisible(false)}
        />
        <Toaster />
        <Script
          id="segment"
          dangerouslySetInnerHTML={{
            __html: renderSnippet()
          }}
        />
        {loadGrowSurf && (
          <Script
            id="growsurf"
            strategy="lazyOnload"
            dangerouslySetInnerHTML={{
              __html: `(function(g,r,s,f){g.grsfSettings={campaignId:"2zr51b",version:"2.0.0"};s=r.getElementsByTagName("head")[0];f=r.createElement("script");f.async=1;f.src="https://app.growsurf.com/growsurf.js"+"?v="+g.grsfSettings.version;f.setAttribute("grsf-campaign", g.grsfSettings.campaignId);!g.grsfInit?s.appendChild(f):"";})(window,document);`
            }}
          />
        )}

        <AppLayout>{getLayout(<Component {...pageProps} />)}</AppLayout>
      </ThemeCreator>
    </RecoilRoot>
  );
}

export default MyApp;
