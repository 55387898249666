/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect } from "react";
import { Select as RootSelect, SelectProps, MenuItem } from "@mui/material";

import styles from "./styles.module.scss";

interface Props<T> extends SelectProps<T> {
  initialValue?: any;
  onChange(newValue: any): void;
  options: { label: string; value: string | number }[] | undefined;
}

const Select: FC<Props<any>> = ({
  options,
  error,
  initialValue,
  onChange,
  value,
  placeholder,
  id,
  ...selectProps
}) => {
  useEffect(() => {
    if (initialValue) onChange(initialValue);
  }, []);

  return (
    <div className={styles.container}>
      <RootSelect
        {...selectProps}
        id={id}
        variant="standard"
        value={value ?? ""}
        className={`${styles.select} ${selectProps.className}`}
        classes={{ standard: styles.root }}
        MenuProps={{
          classes: { paper: styles.dropdown }
        }}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {options?.map(({ value: optionValue, label }) => (
          <MenuItem
            className={styles.dropdownItem}
            value={optionValue}
            key={optionValue}
          >
            {label}
          </MenuItem>
        ))}
      </RootSelect>
    </div>
  );
};

Select.defaultProps = {
  initialValue: undefined
};

export default Select;
