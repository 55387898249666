/* eslint-disable react/display-name */
import React, {
  createContext,
  FC,
  useContext,
  ComponentType,
  ReactNode
} from "react";

import { Theme } from "./types";
import theme from "./theme.main";

export const ThemeContext = createContext<Theme>({
  theme
});

export const ThemeCreator: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);

export const useTheme = (): Theme => useContext(ThemeContext);

export function Themed<T>(
  WrappedComponent: ComponentType<T>
): FC<Pick<T, Exclude<keyof T, "theme">>> {
  return (props) => <WrappedComponent {...(props as T)} theme={theme} />;
}
