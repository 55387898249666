import React, { FC } from "react";
import { CgClose as CloseIcon } from "react-icons/cg";

import Label from "components/Label";

import Button from "components/Button";
import styles from "./styles.module.scss";

type Props = {
  title?: string;
  onClose: () => void;
};

const ModalHeader: FC<Props> = ({ title, onClose }) => (
  <div className={styles.container}>
    <Label ellipsis color="white">
      {title}
    </Label>
    <Button
      Icon={<CloseIcon className={styles.closeIcon} />}
      className={styles.closeButton}
      onClick={onClose}
    />
  </div>
);

ModalHeader.defaultProps = {
  title: ""
};

export default ModalHeader;
