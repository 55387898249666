import { mapper } from "utils/mapping";
import TextInput from "components/TextInput";
import RadioGroupField from "components/RadioField";
import Textarea from "components/TextArea";
import Select from "components/Select";
import GoogleMapsAutocomplete from "components/GoogleMapsAutocomplete";
import IconSelect from "components/IconSelect";

export const fieldMapper = mapper(
  {
    text: TextInput,
    radio: RadioGroupField,
    area: Textarea,
    select: Select,
    location: GoogleMapsAutocomplete,
    icon_select: IconSelect
  },
  TextInput
);
