import React, { FC, ReactNode } from "react";

import styles from "./styles.module.scss";

interface Props {
  at: ("desktop" | "mobile" | "tablet" | "responsive")[];
  className?: string;
  children: ReactNode;
}

const Media: FC<Props> = ({ at, className, children }) => (
  <div
    className={`${className} ${styles.hidden} ${at
      .map((breakpoint) => styles[breakpoint])
      .join(" ")}`}
  >
    {children}
  </div>
);

Media.defaultProps = {
  className: undefined
};

export default Media;
