/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ElementType, ReactNode, useEffect } from "react";
import { RecoilState, useRecoilState } from "recoil";

import Label from "components/Label";

import { isDefined } from "utils/form";
import { Values, Value, Option } from "../../types";

import styles from "./styles.module.scss";

type Props = {
  id: string;
  Component: ElementType;
  formDefinition: RecoilState<Values>;
  title?: string;
  placeholder?: string;
  options?: Option[];
  error?: string;
  disabled?: boolean;
  initialValue?: Value;
  normalize?: ((currentValue: Value) => Value)[];
  actions?: ReactNode;
  componentProps?: { [key: string]: any };
  parents?: { id: string }[];
  loadingStateDefinition: RecoilState<{ [key: string]: boolean }>;
};

const FormItem: FC<Props> = ({
  id,
  Component,
  formDefinition,
  title,
  placeholder,
  options,
  error,
  disabled,
  initialValue,
  normalize,
  actions,
  componentProps,
  parents = [],
  loadingStateDefinition
}) => {
  const [form, setForm] = useRecoilState<Values>(formDefinition);
  const [formLoading, setFormLoading] = useRecoilState(loadingStateDefinition);

  const isLoading = Object.keys(formLoading).some((key) => formLoading[key]);
  const loading = formLoading[id];

  const currentValue = form[id];

  const onChange = (newValue: Value) => {
    setForm((currentForm) => ({ ...currentForm, [id]: newValue }));
  };

  const visible = parents.every(({ id: parentId }) =>
    isDefined(form[parentId])
  );

  const parentValues: { [key: string]: any } = {};

  parents.forEach(({ id: parentId }) => {
    parentValues[parentId] = form[parentId];
  });

  useEffect(() => {
    if (!visible) onChange(undefined);
  }, [visible]);

  return visible ? (
    <div className={styles.fieldWrapper}>
      {title && (
        <Label color="white" className={styles.title}>
          {title}
        </Label>
      )}
      <div className={styles.field}>
        <Component
          values={form}
          normalize={normalize}
          initialValue={initialValue}
          error={error}
          options={options}
          placeholder={placeholder}
          id={id}
          value={currentValue}
          onChange={onChange}
          disabled={disabled || isLoading}
          loading={loading}
          setFormLoading={(newLoadingState: boolean) =>
            setFormLoading({ ...formLoading, [id]: newLoadingState })
          }
          {...componentProps}
          {...parentValues}
        />
        {actions}
      </div>
    </div>
  ) : null;
};

FormItem.defaultProps = {
  title: "",
  placeholder: "",
  options: [],
  error: undefined,
  disabled: false,
  initialValue: undefined,
  normalize: [],
  actions: null,
  componentProps: {},
  parents: []
};

export default FormItem;
