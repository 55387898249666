import React, {
  FC,
  useMemo,
  useState,
  useEffect,
  ReactNode,
  ElementType,
  FormEvent,
  useRef
} from "react";
import { v4 as uuid } from "uuid";
import { atom, useRecoilState, useRecoilValue } from "recoil";

import { objectIsEmpty } from "utils/object";
import styles from "./styles.module.scss";
import { Values, Field } from "./types";
import FormItem from "./components/FormItem";

export type FieldType = Field;

interface ErrorState {
  [key: string]: string[];
}

export interface FormProps {
  onError?(errors: { [key: string]: string[] }): void;
  id?: string;
  name?: string;
  initialValues?: Values;
  fields: (Field | Field[])[];
  children({
    handleSubmit,
    invalid,
    formToRender,
    values,
    clearForm,
    resetToInitialValues,
    formLoading
  }: {
    handleSubmit: (e?: FormEvent<HTMLButtonElement>) => void;
    invalid: boolean;
    formToRender: ReactNode;
    values: Values;
    clearForm(): void;
    resetToInitialValues(values?: Values): void;
    formLoading: boolean;
  }): JSX.Element;
  className?: string;
  onSubmit(
    values: Values,
    initialValues: Values
  ): Promise<void> | void | Promise<boolean>;
  renderExternally?: boolean;
  disabled?: boolean;
  clearOnSubmit?: boolean;
  resetInitialValues?: boolean;
}

interface FormConstructorProps {
  onError?(errors: { [key: string]: string[] }): void;
  id?: string;
  name?: string;
  initialValues?: Values;
  fields: (Field | Field[])[];
  children({
    handleSubmit,
    invalid,
    formToRender,
    values,
    clearForm,
    resetToInitialValues,
    formLoading
  }: {
    handleSubmit: (e?: FormEvent<HTMLButtonElement>) => void;
    invalid: boolean;
    formToRender: ReactNode;
    values: Values;
    clearForm(): void;
    resetToInitialValues(values?: Values): void;
    formLoading: boolean;
  }): JSX.Element;
  className?: string;
  onSubmit(
    values: Values,
    initialValues: Values
  ): Promise<void> | void | Promise<boolean>;
  renderExternally?: boolean;
  disabled?: boolean;
  clearOnSubmit?: boolean;
  fieldMapper(key: string): ElementType;
  resetInitialValues?: boolean;
}

const FormConstructor: FC<FormConstructorProps> = ({
  name,
  initialValues = {},
  fields,
  children,
  className,
  onSubmit,
  disabled,
  renderExternally,
  clearOnSubmit,
  fieldMapper,
  id,
  onError,
  resetInitialValues
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const formDefinition = useMemo(
    () => atom({ key: name ?? uuid(), default: {} }),
    []
  );

  const errorStateDefinition = useMemo(
    () => atom({ key: `${name ?? uuid()}-ERRORS`, default: {} }),
    []
  );

  const loadingStateDefinition = useMemo(
    () => atom({ key: `${name ?? uuid()}-LOADING`, default: {} }),
    []
  );

  const [form, setForm] = useRecoilState<Values>(formDefinition);

  const [formErrors, setFormErrors] = useRecoilState<{
    [key: string]: string[];
  }>(errorStateDefinition);

  const formLoading = useRecoilValue<{
    [key: string]: boolean;
  }>(loadingStateDefinition);

  const isLoading = Object.keys(formLoading).some((key) => formLoading[key]);

  const clearForm = () => {
    setForm({});
    setFormErrors({});
  };

  useEffect(() => () => clearForm(), []);

  const resetToInitialValues = (newValues?: Values) => {
    setForm(newValues || initialValues);
  };

  useEffect(() => {
    if (resetInitialValues) {
      resetToInitialValues(initialValues);
    }
  }, [resetInitialValues]);

  const checkForErrors = () => {
    let newErrors: ErrorState = {};

    fields.flat().forEach(({ id: fieldId, validate, dependencies }) => {
      validate?.forEach((validation) => {
        const result = validation(form[fieldId]);

        if (result)
          newErrors = {
            ...newErrors,
            [fieldId]: [...(newErrors[fieldId] ?? []), result]
          };
      });

      dependencies?.forEach(
        ({ id: dependencyId, validate: dependencyValidations }) => {
          dependencyValidations?.forEach((validation) => {
            const result = validation(form[fieldId], form[dependencyId]);

            if (result)
              newErrors = {
                ...newErrors,
                [fieldId]: [...(newErrors[fieldId] ?? []), result]
              };
          });
        }
      );
    });

    setFormErrors(newErrors);

    if (!objectIsEmpty(newErrors)) setInvalid(true);

    setInvalid(false);
    return newErrors;
  };

  useEffect(() => {
    if (submitted && invalid) checkForErrors();
  }, [form]);

  const handleSubmit = async (e?: FormEvent) => {
    const newSubmitEvent = new Event("submit", { bubbles: true });
    newSubmitEvent.preventDefault();

    e?.preventDefault();
    e?.stopPropagation();

    setSubmitted(true);

    const newErrors = checkForErrors();

    if (objectIsEmpty(newErrors)) {
      const result = await onSubmit(form, initialValues);

      if (result !== false) {
        formRef.current?.dispatchEvent(newSubmitEvent);
        if (clearOnSubmit) clearForm();
      }
    } else {
      onError?.(newErrors);
    }
  };

  const fieldRenderer = ({
    id: fieldId,
    type,
    title,
    placeholder,
    options,
    disabled: disabledField,
    normalize,
    actions,
    props,
    parents
  }: FieldType) => (
    <FormItem
      initialValue={initialValues?.[fieldId]}
      disabled={disabledField || disabled}
      key={fieldId}
      error={formErrors?.[fieldId]?.[0]}
      options={options}
      placeholder={placeholder || title}
      title={title}
      id={fieldId}
      Component={fieldMapper(type)}
      formDefinition={formDefinition}
      loadingStateDefinition={loadingStateDefinition}
      normalize={normalize}
      actions={actions}
      componentProps={props}
      parents={parents}
    />
  );

  const formToRender = fields.map((row) => {
    if (Array.isArray(row)) {
      return (
        <div
          key={row.map(({ id: fieldId }) => fieldId).join("-")}
          className={styles.inlineFields}
        >
          {row.map(fieldRenderer)}
        </div>
      );
    }
    return fieldRenderer(row);
  });

  return (
    <>
      <form id={id} ref={formRef} />
      <form onSubmit={handleSubmit} className={`${styles.form} ${className}`}>
        {!renderExternally && formToRender}
        <div className={renderExternally ? undefined : styles.children}>
          {children({
            handleSubmit,
            invalid,
            formToRender,
            values: form,
            clearForm,
            resetToInitialValues,
            formLoading: isLoading
          })}
        </div>
      </form>
    </>
  );
};

FormConstructor.defaultProps = {
  name: undefined,
  initialValues: {},
  className: "",
  renderExternally: false,
  disabled: false,
  clearOnSubmit: false,
  id: undefined,
  onError: undefined,
  resetInitialValues: false
};

export default FormConstructor;
