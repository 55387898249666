/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, forwardRef } from "react";
import { Input } from "@mui/material";

import styles from "./styles.module.scss";

type Props = {
  onChange: (newValue: string | undefined) => void;
  placeholder?: string;
  initialValue?: string;
  normalize?: ((value: string) => string)[];
  id?: string;
  disabled?: boolean;
  type?: string;
  autoComplete?: string;
  className?: string;
  value: string | undefined;
};

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      onChange,
      placeholder,
      initialValue,
      normalize,
      disabled,
      type = "text",
      autoComplete = "on",
      id,
      className
    },
    ref
  ) => {
    useEffect(() => {
      if (initialValue) onChange(initialValue);
    }, []);

    return (
      <div className={styles.container}>
        <Input
          disableUnderline
          ref={ref}
          id={id}
          value={value ?? ""}
          autoComplete={autoComplete}
          disabled={disabled}
          placeholder={placeholder}
          classes={{ root: `${styles.input} ${className}` }}
          type={type}
          onChange={(event: any) => {
            const { value: newValue } = event.target;
            if (!newValue) onChange(undefined);
            else
              onChange(normalize?.reduce((acc, curr) => curr(acc), newValue));
          }}
        />
      </div>
    );
  }
);

TextInput.defaultProps = {
  placeholder: "",
  initialValue: undefined,
  normalize: [],
  disabled: false,
  type: "text",
  autoComplete: "on",
  id: undefined,
  className: undefined
};

export default TextInput;
