import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { sessionState } from "./atoms";
import { utmParams } from "./constants";
import { Session } from "./types";

export const useCaptureSession = (): Session => {
  const { isReady, query } = useRouter();
  const [session, setSession] = useRecoilState(sessionState);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (isReady) setMounted(true);
  }, [isReady]);

  useEffect(() => {
    if (mounted) {
      const utm: { [key: string]: string } = {};

      Object.keys(query)
        .filter((key) => utmParams.includes(key))
        .forEach((key) => {
          const currentQuery = query[key];

          utm[key] =
            typeof currentQuery === "string"
              ? currentQuery
              : Array.isArray(currentQuery)
              ? currentQuery[0]
              : "";
        });

      setSession({ utm });
    }
  }, [mounted]);

  return session;
};

export const useSession = (): Session => {
  return useRecoilValue(sessionState);
};
