import { Theme } from "theme/types";

export const getVariantStyles = (theme: Theme, variant: string) => {
  const allVariants = variant.split("-");
  let typeStyles = {};

  allVariants.forEach((subVariant) => {
    const styleToAdd = theme.label[subVariant] ?? theme.label[subVariant];
    typeStyles = { ...typeStyles, ...(styleToAdd ?? {}) };
  });

  return typeStyles;
};
